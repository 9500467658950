export const voivodship = [
    "dolnośląskie", 
    "kujawsko-pomorskie", 
    "lubelskie", 
    "lubuskie", 
    "łódzkie", 
    "małopolskie", 
    "mazowieckie", 
    "opolskie", 
    "podkarpackie", 
    "podlaskie", 
    "pomorskie", 
    "śląskie", 
    "świętokrzyskie", 
    "warmińsko-mazurskie", 
    "wielkopolskie", 
    "zachodniopomorskie"
];

export const criteria =[
    'specjalista psychoterapii uzależnień z certyfikatem', 
    'specjalista psychoterapii uzależnień w trakcie certyfikacji', 
    'psychoterapeuta z certyfikatem', 
    'psychoterapeuta w trakcie szkoły psychoterapii', 
    'psycholog pracujących w placówkach leczenia uzależnień',
];

export const timeGroup = [
    'I stopień – „Podstawowe założenia DM” zostanie zrealizowany w dniu 11.05.2024 r. w godz. 9.00-17.00',
    'I stopień + II stopień – „Ćwiczenia komunikacji z uczniem w duchu DM” zostanie zrealizowany stacjonarnie w Lublinie dnia 01.06.2024 w godz. 9.00-17.00',
    //'I stopień + II stopień'
]

export const autismTime = [
    '31.05.2024 w godz. 15.00-19.00',  
    '01.06.2024 w godz. 9.00-18.00' 
    
]