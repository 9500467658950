import React from "react";
import SingleProject from "../../components/SingleProject.js";
import clsx from "clsx";
import styles from './AutismTraining.module.scss';
import { Link } from "react-router-dom";
import { data } from './autism-data.js';

const AutismTraining = () => {

    return(
        <div className={clsx(styles.addiction, "container")}>
            <SingleProject
                status={data.status}
                period={data.period}
                title={data.title}
                text={data?.addictionString}
                image={data.image}
                contact={data.contact}
                showImage={data.showImage}
            />
            <p className="p-2 m-1">Zgłoszenie: za pomocą <b><Link className={clsx(styles.addiction__blinking)} to='/clinic/select_clinic/autism_training2'>formularza</Link></b> na stronie www.natanaelum.pl</p>
        </div>
    )
}

export default AutismTraining;