export const data = {
    addictionString: 
            
        `<p>Stowarzyszenie Natanaelum zaprasza na szkolenie pn. „Techniki Dialogu Motywującego DM w pracy z uczniem przejawiającym zachowania problemowe (uczniem z oporem).”</p>
        <p>Celem szkolenia jest zwiększenie kompetencji specjalistów psychoterapii uzależnień, profilaktyków, psychologów, pedagogów i wychowawców w zakresie stosowania technik DM w pracy z dziećmi i młodzieżą przejawiającą zachowania problemowe.</p>
        <p>Szkolenie oparte będzie na efektywnych metodach pomagania. Podczas szkolenia będą stosowane różne formy pracy: wykłady, warsztaty, analizy przypadków, ćwiczenia.  Uczestnicy w czasie szkolenia będą mogli doświadczyć w małych grupach symulowanego procesu rozmowy z uczniem z zastosowaniem DM, podejmując różne role z perspektywy: terapeuty/nauczyciela i ucznia.</p>
        <p>Dialog Motywujący jest metodą pracy opartą na empatii, uwydatnianiu sprzeczności, podążaniu za oporem i wspieraniu poczucia własnej wartości. Nabycie umiejętności pracy tą metodą jest szczególnie przydatne do pracy z osobą oporującą, niezmotywowaną do rozwoju.</p>
        <p>Szkolenie obejmuje dwa zjazdy po 10 godzin (45 min.). Razem 20 godz.</p>
        <p>I część – „Podstawowe założenia DM” zostanie zrealizowany w dniu 11.05.2024 r. w godz. 9.00-17.00 (stacjonarnie- Lublin).</p>
        <p>II część – „Ćwiczenia komunikacji z uczniem w duchu DM” zostanie zrealizowany stacjonarnie w Lublinie dnia 01.06.2024 w godz. 9.00-17.00 (stacjonarnie- Lublin).</p>
        <p>Szkolenia prowadzi mgr Urszula Grodzka - dyrektor Centrum Dialogu Motywującego i Psychoterapii w Warszawie, psycholog, superwizor dialogu motywującego, socjoterapeuta, doradca zawodowy, certyfikowany Terapeuta Motywujący (certyfikat nr 9). Ma ponad 25 lat doświadczenia zawodowego w pracy z młodzieżą trudną, zagrożoną uzależnieniem i ich rodzinami.</p>
        <p>Liczba osób w grupie 18</p>
        <p>Miejsce realizacji: Lublin</p>
        <p>Koszt: 400 zł. </p>
        <p>Nr rachunku bankowego do wpłat:  63194010763100069500000000</p>
        `,

    status: 'NABÓR TRWA',
    period: 'III-IV 2024',
    title: 'Techniki Dialogu Motywującego DM w pracy z uczniem przejawiającym zachowania problemowe (uczniem z oporem).',
    image:'',
    contact:'',
    showImage:'false',
    link:'dm_training'

}