export const data = {
    addictionString: 
            
        `<p>Stowarzyszenie Natanaelum zaprasza na szkolenie pn. „Diagnoza zaburzeń ze spektrum autyzmu u osób dorosłych” </p>
        <p>Celem szkolenia jest zwiększenie kompetencji specjalistów psychoterapii uzależnień oraz psychologów w zakresie rozpoznania zaburzeń ze spektrum autyzmu u osób dorosłych w diagnozie i leczeniu innych zaburzeń.</p>
        <p>Podczas szkolenia będą stosowane różne formy pracy: wykłady, warsztaty, analizy przypadków.  </p>
        <p>Szkolenie obejmuje 15 godzin (45 min.) realizowanych w ciągu dwóch dni.</p>
        <p>31.05.2024 w godz. 15.00-19.00  </p>
        <p>01.06.2024 w godz. 9.00-18.00 </p>
        <p>Szkolenia prowadzi: dr hab. Anna Prokopiak, pracownik naukowy UMCS, kierownik Katedry Psychopedagogiki Specjalnej, prezeska Fundacji ALPHA, honorowy członek AUTISME-EUROPE.</p>
        <p>Liczba osób w grupie: 16</p>
        <p>Miejsce realizacji: Lublin</p>
        <p>Koszt: 350 zł od osoby </p>
        <p>Nr rachunku bankowego do wpłat:  63194010763100069500000000</p>
        `,

    status: 'NABÓR TRWA',
    period: 'V-VI 2024',
    title: 'Diagnoza zaburzeń ze spektrum autyzmu u osób dorosłych',
    image:'',
    contact:'',
    showImage:'false',
    link:'autism_training'

}